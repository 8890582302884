<template>
  <div class="church-presentation-template">
    <page-body class>
      <h1>{{ translations.tcMembershipApplcationIO }}</h1>
      <b-form validated>
        <section class="section-3 bg-block mb-4">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">{{ translations.tcSecureProcessForSubmitting }}</h2>
            </header>
            <div>
              <b-row>
                <b-col sm="8">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="select-group-1"
                        :label="translations.tcRegion + ':'"
                        label-for="prefix-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-select
                          id="Region-select"
                          :title="translations.tcRegion"
                          :options="regions"
                          v-model="region.org_key"
                          @change="regionChange($event)"
                          class="g-select flex-0 mr-3 mb-3"
                        >
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        id="select-group-1"
                        :label="translations.tcCountry + ':'"
                        label-for="prefix-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <b-form-select
                          id="Country-select"
                          :title="translations.tcCountry"
                          :options="countries"
                          v-model="country.org_key"
                          @change="countryChange"
                          class="g-select flex-0 mr-3 mb-3"
                        >
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        id="select-group-1"
                        :label="translations.tcCamp + ':'"
                        label-for="prefix-select"
                        label-class="form-group-label"
                        class="form-element"
                      >
                        <div v-if="!controlChange">
                          <b-form-select
                            id="Camp-select"
                            :title="translations.tcCamp"
                            :options="camps"
                            v-model="camp.org_key"
                            @change="campChange"
                            class="g-select flex-0 mr-3 mb-3"
                          >
                          </b-form-select>
                        </div>
                        <div v-if="controlChange">
                          <b-form-input
                            id="first-name-input"
                            :placeholder="translations.tcCampName"
                            v-model="applicantInfo.campname"
                            required
                            trim
                          >
                          </b-form-input>
                        </div>
                        <b-form-checkbox v-model="controlChange" name="check-button" switch @change="switchCampControl">
                          {{ translations.tcAddUnlistedCamp }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-1"
                        :label="translations.tcApplicantName + ':'"
                        label-for="first-name-input"
                        class="form-element"
                        label-class="form-group-label"
                      >
                        <template slot="label" class="form-group-label">{{ translations.tcApplicantName }}:</template>
                        <b-form-input
                          :title="translations.tcFullName"
                          id="first-name-input"
                          :placeholder="translations.tcApplicantName"
                          v-model="applicantInfo.fullname"
                          required
                          trim
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-1"
                        :label="translations.tcFileUpload + ':'"
                        label-for="file-upload-input"
                        class="form-element"
                        label-class="form-group-label"
                      >
                        <template slot="label" class="form-group-label">{{ translations.tcUploadFiles }}:</template>
                        <b-form-file
                          multiple
                          v-model="applicantInfo.files"
                          ref="file-input"
                          class="mb-2"
                          :file-name-formatter="formatNames"
                          :browse-text='translations.tcBrowse'
                          :placeholder='translations.tcNoFileChosen'
                          :drop-placeholder="translations.tcDropFileHere"
                        ></b-form-file>
                        <b-card-group deck class="mb-3">
                          <b-card :header="translations.tcListOfUploadFiles" class="text-left">
                            <b-card-text v-for="item in files" :key="item.name">{{ item.name }}</b-card-text>
                            <b-button href="#" variant="primary" @click.prevent="clearFiles">
                              {{ translations.tcClearAllFiles }}
                            </b-button>
                          </b-card>
                        </b-card-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-1"
                        label="translations.tcOtherInformation + ':'"
                        label-for="note-input"
                        class="form-element"
                        label-class="form-group-label"
                      >
                        <template slot="label" class="form-group-label">{{ translations.tcOtherInformation }}:</template>
                        <b-form-textarea
                          id="textarea-large"
                          size="lg"
                          :placeholder="translations.tcNotes"
                          v-model="applicantInfo.notes"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group
                        id="input-group-1"
                        :label="translations.tcFirstName + ':'"
                        label-for="first-name-input"
                        class="form-element"
                        label-class="form-group-label"
                      >
                        <template slot="label" class="form-group-label">{{ translations.tcNameOfOfficer }}:</template>
                        <span>{{ userName }}</span>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-5 pt-3 pl-0 pl-sm-4">
          <div class="body">
            <div class="d-flex">
              <b-button @click="handleSaveClick" variant="primary" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
                {{ translations.tcSubmit }}
              </b-button>
              <b-button @click="routeToCampHome" variant="tertiary" class="flex-0 w-100-sd mb-sm-0">
                {{ translations.tcCancel }}
              </b-button>
            </div>
          </div>
        </section>
      </b-form>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'application-upload-file',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      regions: [
        {
          text: 'Select a Region',
          value: constantData.empty_guid,
        },
      ],
      countries: [
        {
          text: 'Select a Country',
          value: constantData.empty_guid,
        },
      ],
      camps: [
        {
          text: 'Select a Camp',
          value: constantData.empty_guid,
        },
      ],
      reportUrls: null,
      region: {
        org_key: null,
        org_name: null,
      },
      country: {
        org_key: null,
        org_name: null,
      },
      camp: {
        org_key: null,
        org_name: null,
      },
      reportCountryData: {
        ind_key: null,
        org_key: null,
      },
      reportCampData: {
        ind_key: null,
        org_key: null,
      },
      reportUrlsData: {
        ind_key: null,
        org_key: null,
      },
      applicantInfo: {
        fullname: '',
        countryname: null,
        countrykey: null,
        campname: null,
        campkey: null,
        files: null,
        notes: null,
        indkey: null,
        regionname: null,
        regionkey: null,
        fileName: [],
      },
      files: [],
      controlChange: false,
    }
  },
  methods: {
    ...mapActions({
      getCamps: 'secureReport/getCamps',
      getCountries: 'secureReport/getCountries',
      getRegions: 'secureReport/getRegions',
      setLoadingStatus: 'menu/setLoadingStatus',
      setRouterBackLink: 'user/setRouterBackLink',
      upsertIOApplicant: 'officersModule/upsertIOApplicant',
    }),
    formatNames(files) {
      this.files = files
      return files.length === 1 ? files[0].name : `${files.length} ${this.translations.tcFilesSelected}`
    },
    clearFiles() {
      this.$refs['file-input'].reset()
    },
    switchCampControl(checked) {
      return (this.controlChange = checked === false ? false : true)
    },
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.getRegions(),
        (this.regions = this.regionDetails.map((item) => {
          return {
            text: item.org_name,
            value: item.org_key,
          }
        })),
        await this.updateDropdownPlaceholder([
          { dataVar: 'regions', text: 'Region' },
          { dataVar: 'countries', text: 'Country' },
          { dataVar: 'camps', text: 'Camp' }
        ]),
        (this.region.org_key = constantData.empty_guid),
        (this.country.org_key = constantData.empty_guid),
        (this.camp.org_key = constantData.empty_guid),
      ]).then(() => {
        this.setLoadingStatus(false)
      })
    },
    async regionChange(evt) {
      this.setLoadingStatus(true)
      if (!!this.region.org_key) {
        this.reportCountryData.ind_key = this.userId
        this.reportCountryData.org_key = this.region.org_key
        let regionname = this.regions.find((option) => option.value === evt)
        this.region.org_key = evt
        this.region.org_name = regionname.text
        await this.getCountries(this.reportCountryData)
        this.countries = this.countryDetails.map((item) => {
          return {
            text: item.t_org_name,
            value: item.t_org_key,
          }
        })
        await this.updateDropdownPlaceholder([{ dataVar: 'countries', text: 'Country'}])
        this.country.org_key = constantData.empty_guid
        this.reportUrls = null
      }
      this.setLoadingStatus(false)
    },
    async countryChange(evt) {
      this.setLoadingStatus(true)
      this.reportCampData.ind_key = this.userId
      this.reportCampData.org_key = this.country.org_key
      let countryname = this.countries.find((option) => option.value === evt)
      this.country.org_key = evt
      this.country.org_name = countryname.text
      await this.getCamps(this.reportCampData)
      this.camps = this.campDetails.map((item) => {
        return {
          text: item.t_org_name,
          value: item.t_org_key,
        }
      })
      await this.updateDropdownPlaceholder([{ dataVar: 'camps', text: 'Camp'}])
      this.camp.org_key = constantData.empty_guid
      this.reportUrls = null
      this.setLoadingStatus(false)
    },
    async campChange(evt) {
      this.setLoadingStatus(true)
      this.reportUrlsData.ind_key = this.userId
      this.reportUrlsData.org_key = this.camp.org_key
      let campname = this.camps.find((option) => option.value === evt)
      this.camp.org_key = evt
      this.camp.org_name = campname.text
      this.setLoadingStatus(false)
    },
    async handleSaveClick() {
      try {
        if (this.validateForm()) {
          this.applicantInfo.campname = this.camp.org_name
          this.applicantInfo.campkey = this.camp.org_key
          this.applicantInfo.countryname = this.country.org_name
          this.applicantInfo.countrykey = this.country.org_key
          this.applicantInfo.indkey = this.userId
          this.applicantInfo.regionname = this.region.org_name
          this.applicantInfo.regionkey = this.region.org_key
          let payload = this.applicantInfo
          let result = true
          let filedoc = []
          let formData = new FormData()
          for (let itm in payload) {
            formData.append(itm, payload[itm])
          }
          for (let file in this.applicantInfo.files) {
            formData.append('file', this.applicantInfo.files[file])
            formData.append('FileName', this.applicantInfo.files[file].name)
          }
          await Promise.all([this.setLoadingStatus(true), (result = await this.upsertIOApplicant(formData))]).then(
            () => {
              if (result.data && result.data.response === '0') {
                console.error('errormessage: ', this.errMessage)
                this.errMessage = result.data.message
                console.error('errormessage: ', this.errMessage)
                result = false
              }
              this.setLoadingStatus(false)
              this.$swal({
                icon: result ? 'success' : 'error',
                text: result
                  ? this.translations.tcApplicantSaved
                  : this.translations.tcErrorApplicantNotSaved,
                confirmButtonText: this.translations.tcOk,
              }).then((confirm) => {
                if (confirm) {
                  this.routeToCampHome()
                }
              })
            }
          )
        }
      } catch (error) {
        this.setLoadingStatus(false)
        console.error(error)
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorApplicationNotSaved,
          confirmButtonText: this.translations.tcOk,
        })
      }
    },
    validateForm() {
      let rtn = true
      if (this.applicantInfo.fullname === '') {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorFullNameRequired,
          confirmButtonText: this.translations.tcOk,
        })
      }
      return rtn
    },
    async handleCancelClick(evt) {
      this.individual.birthdate = evt
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    routeToCampHome() {
      this.$router.push({ name: 'camp-officers-home' })
    },
    async updateDropdownPlaceholder (listOfPlaceholders) {
      if (!listOfPlaceholders) return false

      listOfPlaceholders.forEach(placeholder => {
        const { dataVar, text } = placeholder
        this[dataVar].unshift({
          text: this.translations[`tcSelectA${text}`] || `Select a ${text}`,
          value: constantData.empty_guid
        })
      })
    }
  },
  async created () {
    //need a selected individual or redirect back to membership landing
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('church-search'),
        this.pageLoad(),
      ]).then(results => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
      })
    } catch (e) {
      console.error('Error in ApplicationFileUpload created(), ', e)
    } finally {
    }
  },
  computed: {
    ...mapGetters({
      campDetails: 'secureReport/camps',
      countryDetails: 'secureReport/countries',
      prefCulture: 'user/userPreferredCulture',
      regionDetails: 'secureReport/regions',
      userCamp: 'user/userCamp',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userName: 'user/userName',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),
    optionsStates() {
      return [...this.convertedProspectFormOptions.states]
    },
    optionsCountries() {
      return [...this.convertedProspectFormOptions.countries]
    },
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.form-group-label {
  margin-bottom: 0;
  color: #636363;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px !important;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22px;
}

h3 {
  line-height: 1;
}

.church_image {
  background-size: 100% 100%;
}

.check_label {
  position: relative;
  top: -40px;
  left: 30px;
  font-family: 'Open Sans';
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.btn.btn-outline,
.btn.btn-outline-primary {
  text-transform: uppercase;
  font-weight: 700;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
